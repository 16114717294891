import React, { memo, RefObject, useCallback, useRef, useState } from 'react';

import useFetchSessionUser from '~/app/lib/hooks/useFetchSessionUser';
import { useI18n } from '~/app/lib/i18n';
import FadeOnMount from '../FadeOnMount';
import MoreIcon from '../Icon/MoreIcon';
import EditIcon from '../Icon/EditIcon';
import PageHeader from '../PageHeader';
import Clickable from '../Clickable';
import Box from '../Box';

import ItemPageActions, { ItemPageActionsProps } from './ItemPageActions';
import { useItemContext } from './ItemPageContext';

export interface ItemPageHeaderProps {
  isOwned: boolean | undefined;
  pagePath: string;
  artistPagePath: string;
  userCanEdit: boolean | undefined;
  renderBeforeHeader?: () => JSX.Element | null | undefined;
  renderContent?: () => JSX.Element | null | undefined;
  contentNodeRef?: RefObject<HTMLDivElement>;
  toActionItems: ItemPageActionsProps['toActionItems'];
  displayType: ItemPageActionsProps['displayType'];
  shareText: ItemPageActionsProps['shareText'];
  shareUrl: ItemPageActionsProps['shareUrl'];
  copyShareUrl: () => void;
}

const ItemPageHeader = memo<ItemPageHeaderProps>(
  ({
    pagePath,
    artistPagePath,
    isOwned,
    userCanEdit,
    shareUrl,
    copyShareUrl,
    renderBeforeHeader,
    renderContent,
    contentNodeRef,
    toActionItems,
    displayType,
    shareText,
  }) => {
    const { t } = useI18n();
    const { isLoggedIn } = useFetchSessionUser();
    const [actionsTrayOpen, setActionsTrayOpen] = useState(false);
    const actionButtonRef = useRef<HTMLDivElement>(null);

    // always ensure the menu is visible for logged in (orchard) users
    const hideMenuButton = !isLoggedIn;

    const {
      data: { item },
    } = useItemContext();

    return (
      <>
        {renderBeforeHeader?.()}
        <PageHeader
          withMenuButton={!hideMenuButton}
          renderRight={useCallback(() => {
            return (
              <Box flexRow alignCenter fullHeight>
                {userCanEdit && (
                  <FadeOnMount>
                    <div>
                      <Clickable
                        href={`${pagePath}/edit`}
                        padding="0 .65rem"
                        withHoverOpacityFrom={0.9}
                        title={t('item.actions.editPage')}
                        testId="editPage"
                      >
                        <EditIcon size="2.5rem" />
                      </Clickable>
                    </div>
                  </FadeOnMount>
                )}
                <div ref={actionButtonRef} style={{ margin: '0 -0.8rem' }}>
                  <Clickable
                    testId="actionMenuButton"
                    onClick={() => setActionsTrayOpen(true)}
                    title={t('item.actions.openActionsMenu')}
                    centerContent
                    fullWidth
                    fullHeight
                  >
                    <MoreIcon size="3.75rem" direction="right" />
                  </Clickable>
                </div>
              </Box>
            );
          }, [userCanEdit])}
        >
          <Box
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: userCanEdit ? '8rem' : '6rem',
              right: userCanEdit ? '7rem' : '5rem',
              willChange: 'opacity,transform',
            }}
            centerContent
            nodeRef={contentNodeRef}
          >
            <div style={{ width: '100%' }}>
              {renderContent && renderContent()}
            </div>
          </Box>
          <ItemPageActions
            isOpen={actionsTrayOpen}
            item={item}
            targetElRef={actionButtonRef}
            onClose={() => setActionsTrayOpen(false)}
            isOwned={isOwned!}
            userCanEdit={!!userCanEdit}
            artistPagePath={artistPagePath}
            pagePath={pagePath}
            copyShareUrl={copyShareUrl}
            toActionItems={toActionItems}
            displayType={displayType}
            shareText={shareText}
            shareUrl={shareUrl}
          />
        </PageHeader>
      </>
    );
  }
);

export default ItemPageHeader;
