import { useEffect } from 'react';
import Debug from 'debug';

import { toPublicEndpoint } from '../../getPublicEndpoint';
import { useSelector } from '~/app/lib/store/redux';
import { useAppRouter } from '../useAppRouter';
import { forceWindowLoad } from '../utils';

import {
  selectAccountIdSessionScope,
  selectSessionCustomDomain,
} from '~/app/lib/store/session/selectors';

const debug = Debug('songwhip/router/useRedirectIfNeeded');

/**
 * Checks that the current router path matches the given pagePath.
 * When the mismatch it re-routes to the given pagePath.
 */
export const useRedirectIfNeeded = ({
  pagePath,
  pageOwnedByAccountIds,
}: {
  pagePath: string;
  pageOwnedByAccountIds: number[] | undefined;
}) => {
  const router = useAppRouter();
  const currentPath = router.getAsPathname();
  const accountIdScope = useSelector(selectAccountIdSessionScope);
  const isCustomDomain = !!useSelector(selectSessionCustomDomain);

  useEffect(() => {
    if (isCustomDomain) {
      return;
    }

    const expectedPath = router.applyPathScope(pagePath);
    const shouldRedirect = expectedPath !== currentPath;

    // pagePath mismatch
    if (shouldRedirect) {
      debug('redirecting %s -> %s', currentPath, pagePath, {
        expectedPath,
        currentPath,
        pagePath,
      });

      router.replace(pagePath);
      return;
    }

    const isOutOfAccountScope =
      accountIdScope &&
      (!pageOwnedByAccountIds ||
        !~pageOwnedByAccountIds.indexOf(accountIdScope));

    if (isOutOfAccountScope) {
      forceWindowLoad(toPublicEndpoint(pagePath));
      return;
    }
  }, [pagePath, currentPath, pageOwnedByAccountIds]);
};
