import Box from '../Box';
import CrossIcon from '../Icon/CrossIcon';
import Text from '../Text';

export const ItemBlockedText = ({
  testId = 'itemBlockedText',
  text,
  textColor,
}: {
  text: string;
  textColor?: string;
  testId?: string;
}) => {
  return (
    <Box testId={testId} flexColumn centerContent style={{ gap: '1rem' }}>
      <CrossIcon size="7.45rem" />
      <Text centered size="1.5rem" color={textColor}>
        {text}
      </Text>
    </Box>
  );
};
