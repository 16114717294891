import { RefObject, useEffect } from 'react';

const useOnCopyGesture = ({
  nodeRef,
  callback,
}: {
  nodeRef: RefObject<HTMLDivElement>;
  callback: () => void;
}) => {
  useEffect(() => {
    const node = nodeRef.current;
    if (!node) return;

    // on small-screen a double tap anywhere that's
    // not a click target will copy the share url
    const unlisten = onDoubleTap(node, callback);

    return () => {
      unlisten();
    };
  }, [nodeRef]);
};

const onDoubleTap = (el: HTMLElement, callback: () => void) => {
  let timestamp = 0;

  const onClick = () => {
    const elapsed = Date.now() - timestamp;

    // if the time since the last click is
    // less than 250ms it's a double tap
    if (elapsed < 250) {
      // clear any text selection caused by double click
      clearSelection();
      callback();
      return false;
    }

    timestamp = Date.now();
  };

  el.addEventListener('click', onClick);

  return () => {
    el.removeEventListener('click', onClick);
  };
};

const clearSelection = () => {
  if (window.getSelection) {
    window.getSelection()?.removeAllRanges();
  }
};

export default useOnCopyGesture;
