import { isIos } from '~/app/lib/device/utils';

const fallback = (text: string) => {
  const textarea = document.createElement('textarea');

  // create textarea
  textarea.value = text;

  // ios will zoom in on the input if the font-size is < 16px
  textarea.style.fontSize = '20px';
  document.body.appendChild(textarea);

  // select text
  if (isIos(navigator.userAgent)) {
    const range = document.createRange();
    range.selectNodeContents(textarea);

    const selection = window.getSelection() as Selection;
    selection.removeAllRanges();
    selection.addRange(range);
    textarea.setSelectionRange(0, 999999);
  } else {
    textarea.select();
  }

  // copy selection
  document.execCommand('copy');

  // cleanup
  document.body.removeChild(textarea);
};

export default async (text: string) => {
  try {
    // this will throw if the api doesn't exist or if it's
    // been blocked by security policy (inside facebook/ig webview)
    // eslint-disable-next-line compat/compat
    await navigator.clipboard.writeText(text);
  } catch (error) {
    fallback(text);
  }
};
