import { useI18n } from '~/app/lib/i18n';
import { ItemBlockedOverlay } from './ItemBlockedOverlay';
import { ItemBlockedText } from './ItemBlockedText';

export const ItemCountryBlocked = () => {
  const { t } = useI18n();

  return (
    <ItemBlockedOverlay testId="countryBlocked">
      <ItemBlockedText text={t('item.countryBlocked')} />
    </ItemBlockedOverlay>
  );
};
