import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';
import { SchemaTypeBase, SchemaIdReference } from './types';

interface SchemaArtist extends SchemaTypeBase {
  '@type': 'MusicGroup';
  name: string;
  url: string;
  image?: SchemaIdReference;
  subjectOf?: SchemaIdReference;
}

type ToArtistParams = {
  name: string;
  url: string;
  imageId?: string;
  webPageId?: string;
};

export default ({ name, url, imageId, webPageId }: ToArtistParams) => {
  if (!name) return;

  const image = imageId
    ? {
        '@id': imageId,
      }
    : undefined;

  const subjectOf = webPageId
    ? {
        '@id': webPageId,
      }
    : undefined;

  return removeUndefinedKeys<SchemaArtist>({
    '@type': 'MusicGroup',
    '@id': `${url}/#artist`,
    name,
    url,
    image,
    subjectOf,
  });
};
