import React, { useCallback, useRef, useMemo, FC, memo } from 'react';
import dynamic from 'next/dynamic';
import Debug from 'debug';

import { useRedirectIfNeeded } from '~/app/lib/router2/redirectIfNeeded/useRedirectIfNeeded';
import useFetchSessionUser from '~/app/lib/hooks/useFetchSessionUser';
import { removePathSuffix } from '~/app/lib/router2/utils';
import copyToClipboard from '~/app/lib/utils/copyToClipboard';
import { useTracker } from '~/app/lib/tracker/useTracker';
import { withEmitter } from '~/app/lib/hooks/useEmitter';
import { toLinkType } from '~/app/lib/utils/analytics';
import useCustomTracking from './useCustomTracking';
import { useAppToast } from '../NextApp/lib/CoreUi';
import { toRgba } from '~/app/lib/utils/color';
import toShortUrl from '~/app/lib/toShortUrl';
import Text from '~/app/components/Text';
import { useI18n } from '~/app/lib/i18n';
import Page from '~/app/components/Page';
import Box from '~/app/components/Box';
import Link from '../Link2';

import {
  CORE_SECTION_COMPONENTS,
  LayoutSlotIds,
  MAX_CONTENT_WIDTH,
} from './constants';

import useOnScrollCallback from './useOnScrollCallback';
import { ItemPageContext } from './ItemPageContext';
import useOnCopyGesture from './useOnCopyGesture';
import ItemPageContent from './ItemPageContent';
import SectionRenderer from './SectionRenderer';
import ItemPageHeader from './ItemPageHeader';
import { ItemPageProps } from './types';
import Background from './Background';
import Gradient from '../Gradient';
import Sticky from '../Sticky';

import {
  PageThemeProvider,
  usePageTheme,
} from './ItemPageEdit/addons/theme/PageThemeContext';
import { useCountryBlocked } from './useCountryBlocked';
import { ItemCountryBlocked } from './ItemCountryBlocked';
import { OrchardBrands } from '~/app/lib/songwhipApi/types';

const debug = Debug('songwhip/components/ItemPage');

const OneTrustLazy = dynamic(() => import('../OneTrust'), { ssr: false });
const ItemAgeGateLazy = dynamic(() => import('./ItemAgeGate'));

const ItemPage: FC<ItemPageProps> = ({
  isLoading,
  isOwned,
  isDraft = false,
  isOrchardPage,
  pageBrand,
  error,
  pagePath,
  artistPagePath,
  hasContent,
  shareText,
  itemContext,
  renderFooterContent,
  renderHeroContent,
  renderBeforeHeader,
  renderHeaderContent,
  pageSectionComponents: layoutComponents,
  headerToolbarHeight,
  heroHeight,
  backgroundHeight,
  userCanEdit,
  pageOwnedByAccountIds,
  testId = 'itemPage',
  toActionItems,
  displayType,
  renderContent,
  onScroll = () => {},
  withRedirectIfNeeded = true,
  ...pageProps
}) => {
  debug('render');

  const headerContentRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>();
  const contentRootRef = useRef<HTMLDivElement>(null);
  const shareUrl = toShareUrl({ isOwned, isDraft, pageBrand });
  const { trackEvent } = useTracker();
  const toast = useAppToast();
  const { t, tx } = useI18n();
  const pageTheme = usePageTheme();
  const isCountryBlocked = useCountryBlocked();
  const { isLoggedIn } = useFetchSessionUser();
  const isOneTrustActive = !isLoggedIn && !isCountryBlocked;

  const layoutComponentsResolved = useMemo(
    () => ({ ...CORE_SECTION_COMPONENTS, ...layoutComponents }),
    []
  );

  const copyShareUrl = useCallback(() => {
    copyToClipboard(shareUrl!);

    trackEvent({
      type: 'link-copied',
      linkType: toLinkType(shareUrl!),
    });

    toast({ text: t('item.events.linkCopied') });
  }, [shareUrl]);

  useCustomTracking(itemContext);

  useOnCopyGesture({
    nodeRef: contentRootRef,
    callback: copyShareUrl,
  });

  if (withRedirectIfNeeded) {
    useRedirectIfNeeded({
      pagePath,
      pageOwnedByAccountIds,
    });
  }

  const onScrollInternal = useOnScrollCallback({
    headerContentRef,
    imageRef,
    titleRef,
  });

  return (
    <>
      {isOneTrustActive && <OneTrustLazy sendConsentEvent={isOwned} />}
      <Page
        testId={testId}
        backgroundColor={pageTheme.backgroundColor}
        textColor={pageTheme.textColor}
        contentStyle={{
          paddingBottom: 0,
        }}
        onScroll={useCallback((params) => {
          onScrollInternal(params);
          onScroll(params);
        }, [])}
        renderBackground={useCallback(
          () => (
            <Background nodeRef={imageRef} height={backgroundHeight} />
          ),
          [itemContext, backgroundHeight]
        )}
        renderHeader={() => {
          if (isCountryBlocked) return <></>;

          return (
            <ItemPageHeader
              pagePath={pagePath}
              artistPagePath={artistPagePath!}
              isOwned={isOwned}
              userCanEdit={userCanEdit}
              displayType={displayType}
              shareText={shareText!}
              shareUrl={shareUrl!}
              copyShareUrl={copyShareUrl}
              renderBeforeHeader={renderBeforeHeader}
              renderContent={renderHeaderContent}
              contentNodeRef={headerContentRef}
              toActionItems={toActionItems}
            />
          );
        }}
        renderContent={useCallback(
          ({ contentContainerProps }) => {
            if (isCountryBlocked) return <ItemCountryBlocked />;

            const sections = itemContext.layout.main.map((item, index) => (
              <SectionRenderer
                key={`${item.component || item.preset}${index}`}
                components={layoutComponentsResolved}
                sectionPath={`${LayoutSlotIds.MAIN}[${index}]`}
                sectionIndex={index}
                itemContext={itemContext}
                pageSection={item}
              />
            ));

            return (
              <Box flexColumn minHeight="100%" {...contentContainerProps}>
                <Box
                  nodeRef={contentRootRef}
                  flexGrow
                  flexColumn
                  style={{
                    background: `linear-gradient(180deg, transparent 0%, transparent 100vh, ${pageTheme.backgroundColor} 100%)`,
                  }}
                >
                  <Sticky
                    zIndex={1}
                    style={{
                      marginTop: '-8rem',
                      marginBottom: '-2rem',
                      pointerEvents: 'none',
                    }}
                  >
                    <Gradient
                      from={toRgba(pageTheme.backgroundColor, 0.65)}
                      to={toRgba(pageTheme.backgroundColor, 0)}
                      height="10rem"
                    />
                  </Sticky>
                  <ItemPageContent
                    // Only show errors if/when there's no content to display,
                    // this effectively hides errors from the UI, but means the
                    // page is still functional. We might eventually show a toast.
                    error={!hasContent ? error : undefined}
                    // only show full page loading spinner if we have no content to show
                    isLoading={isLoading && !hasContent}
                    titleRef={titleRef}
                    headerToolbarHeight={headerToolbarHeight}
                    heroHeight={heroHeight}
                    renderHeroContent={renderHeroContent}
                    renderMainContent={() => {
                      return (
                        <div
                          style={{
                            margin: '0 auto',
                            padding: '0 0 10rem',
                            maxWidth: MAX_CONTENT_WIDTH,
                          }}
                        >
                          {sections}
                        </div>
                      );
                    }}
                  />
                  {renderContent && renderContent()}
                  {(!isLoading &&
                    renderFooterContent &&
                    renderFooterContent()) ??
                    (!isOwned && (
                      <Text
                        centered
                        size="1.2rem"
                        padding="0 0 2rem"
                        lineHeight={1.4}
                        color="#999"
                      >
                        <Link href="/">
                          <Text
                            isInline
                            color={pageTheme.textColor}
                            opacity={0.8}
                          >
                            {tx('item.poweredBy', {
                              songwhip: <Text isInline>Songwhip</Text>,
                            })}
                          </Text>
                        </Link>
                      </Text>
                    ))}
                </Box>
                <style jsx>
                  {`
                    :global(.pageSection:not(:first-child)) {
                      margin-top: 7rem;
                    }
                  `}
                </style>
              </Box>
            );
          },
          [
            itemContext,
            renderHeroContent,
            renderFooterContent,
            renderContent,
            isLoading,
            hasContent,
            error,
            isOwned,
            isCountryBlocked,
          ]
        )}
        footerHeight="4rem"
        footerStyle={{ pointerEvents: 'none' }}
        trackPageView={!!hasContent}
        withOverflowGradients
        gradientColor={pageTheme.backgroundColor}
        {...pageProps}
      />
      {!isCountryBlocked && <ItemAgeGateLazy />}
    </>
  );
};

const toShareUrl = ({
  isOwned,
  isDraft,
  pageBrand,
}: {
  isOwned?: boolean;
  isDraft?: boolean;
  pageBrand?: OrchardBrands;
}) => {
  if (!process.browser) return;

  const { href, pathname, hostname } = location;

  // Custom domains don't contain songwhip.com (eg. foo.sng.to, mydomain.com).
  // This is a bit brittle, we could use a `HOST` env var here instead and
  // test location against that … meh.
  const isCustomDomain = !/songwhip\.com|localhost/.test(hostname);

  // pro pages not 'scoped' behind custom domains share the short url
  // as short-links are preferable for pro users w/o custom domains
  if (isOwned && !isCustomDomain) {
    return toShortUrl({ pagePath: pathname, pageBrand, isDraft });
  }

  // all other pages share the current url, minus search
  // to avoid re-sharing third-party clutter (eg. fbci=123abc…)
  return removePathSuffix(href);
};

const ItemPageMemo = memo(
  withEmitter((props) => {
    const { itemContext } = props;

    return (
      <ItemPageContext.Provider value={itemContext}>
        <PageThemeProvider itemContext={itemContext}>
          <ItemPage {...props} />
        </PageThemeProvider>
      </ItemPageContext.Provider>
    );
  })
) as FC<ItemPageProps>;

export default ItemPageMemo;
