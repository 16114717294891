const LogoSongwhip = ({
  style,
  color = 'currentColor',
  opacity,
  size,
  ...props
}: {
  style?: React.CSSProperties;
  color?: string;
  opacity?: number;
  size?: number | string;
}) => (
  <svg
    viewBox="0 0 100 28"
    style={
      {
        ...style,
        color,
        fill: color,
        height: size,
        opacity,
      } as React.CSSProperties
    }
    {...props}
  >
    <use href="#swlogo" xlinkHref="#swlogo" />
  </svg>
);

export default LogoSongwhip;
