import css from 'styled-jsx/css';
import Box from '../Box';
import Loading from '../Loading';
import LogoSongwhip from '../Logos/LogoSongwhip';

const GAP = '2.63rem';

const styles = css.resolve`
  .root {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const ItemBlockedOverlay = ({
  testId = 'itemBlockedOverlay',
  isLoading = false,
  children,
}: {
  children: React.ReactNode;
  isLoading?: boolean;
  testId?: string;
}) => {
  return (
    <Box
      testId={testId}
      className={`${styles.className} root`}
      coverParent
      centerContent
      zIndex={1}
    >
      <Box
        coverParent
        zIndex={-1}
        style={{
          background: `linear-gradient(0deg,
            #000 31.7%,
            rgba(0, 0, 0, 0.60) 52.88%,
            rgba(0, 0, 0, 0.34) 64.14%,
            rgba(0, 0, 0, 0.15) 81.22%,
            rgba(0, 0, 0, 0.40) 91.46%,
            #000 100%)`,
        }}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <Box testId={testId} flexColumn width="32rem" style={{ gap: GAP }}>
          <LogoSongwhip size="4.9rem" />

          {children}
        </Box>
      )}
      {styles.styles}
    </Box>
  );
};
