import Debug from 'debug';

import { isMobileBrowser } from '~/app/lib/device/utils';

const debug = Debug('songwhip/components/ModalShare/openNativeShare');

export default ({ url, text }) => {
  debug('share', { url, text });

  // desktop native share actions suck as most apps are in browser,
  // additional ios check to use native share on iPads
  const useNativeShare =
    'share' in navigator && isMobileBrowser(navigator.userAgent);

  if (!useNativeShare) return false;

  try {
    navigator.share({ url, text });
    debug('success');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error sharing', error);
    return false;
  }

  return true;
};
