import React, { FC } from 'react';

import { useI18n } from '~/app/lib/i18n';
import { AppError } from '~/app/lib/errors/types';

import { AddonTypes } from '~/app/lib/songwhipApi/types';
import PageMetadata, { PageMetadataProps } from '../../PageMetadata';
import { ItemContext } from '../types';

import verifiedFavicon from './verifiedFavicon.external.svg';
import toSizedImageUrlNext from '~/app/lib/toSizedImageUrlNext';

/**
 * Resolves final page metadata from given defaults and user
 * provided content via metadata addon.
 */
const ItemPageMetadata: FC<{
  itemContext: ItemContext;
  defaultTitle: string | undefined;
  defaultDescription: string | undefined;
  defaultImage: string | undefined;
  error: AppError | undefined;
  pagePath: string;
  isOwned: boolean;
  toStructuredData: PageMetadataProps['toStructuredData'];
}> = ({
  itemContext,
  defaultTitle,
  defaultDescription,
  defaultImage,
  error,
  pagePath,
  isOwned,
  toStructuredData,
}) => {
  const { t } = useI18n();
  const metadataAddon = itemContext.addons[AddonTypes.METADATA];

  const image = metadataAddon?.image || defaultImage;
  const title = metadataAddon?.title || defaultTitle;
  let description = metadataAddon?.description || defaultDescription;

  // og image
  const sizedImage =
    image &&
    toSizedImageUrlNext({
      url: image,
      width: 1200,
      height: 630,
      quality: 50,
      fit: 'cover',
    });

  // append songwhip attribution to non-pro pages
  if (!isOwned) description += ` - ${t('item.pageDescriptionAttribution')}`;

  return (
    <PageMetadata
      error={error}
      title={title}
      description={description}
      canonicalPath={pagePath as string}
      faviconUrl={isOwned ? verifiedFavicon : undefined}
      imageUrl={sizedImage}
      // IDEA: if this is only need for ssr we could save the bytes
      // and not bother bundling toStructuredData on client
      toStructuredData={toStructuredData}
    />
  );
};

export default ItemPageMetadata;
